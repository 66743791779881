<template>
  <div style='position: relative;'>
    <div ref="content" style='position: absolute; left: -5000px;'>
      <!-- 网页内容 -->
      <div>123</div>
      <p style='background-color: red;'>ccc</p>
      <span class='test'>22222</span>
    </div>
    <button @click="capture">截图</button>
    <img :src="image" alt="截图">
  </div>
</template>
<script>
import html2canvas from 'html2canvas';
export default {
  data() {
    return {
      image: ''
    }
  },
  methods: {
    async capture() {
      const canvas = await html2canvas(this.$refs.content, {
        // 配置选项
      });

      console.log(123, canvas.toDataURL())

      this.image = canvas.toDataURL();
    }
  }
}
</script>

<style>
.test{
  color: orange
}
</style>